      /* @font-face {
        font-family: trial;
        src: url("CENTRALESANSCND-BOLD.otf");
        font-weight: bold;
      } */

      .caafont {
          font-family: Comfortaa;
      }

      .font2 {
          font-family: IBM Plex Mono;
      }

      .robotofont {
          font-family: Roboto Slab;
      }

      .scantext {
          color: #000;
          leading-trim: both;
          text-edge: cap;
          font-family: Comfortaa;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
      }

      .scantext2 {
          color: #303030;
          font-family: Comfortaa;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 40px;
          /* 222.222% */
      }

      .score {
          color: #303030;
          font-family: Comfortaa;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 40px;
          /* 222.222% */
      }

      .spbar {
          margin-top: -15px !important;
          margin-bottom: 15px !important;
      }

      .neumorphism {
          width: 700px;
          height: 50px;
          /* background: #ecf0f3; */
          display: flex;
          justify-content: space-around;
          align-items: center;
          /* border-radius: 35px; */

          /* box-shadow: 7px 7px 9px rgba(0, 0, 0, 0.15),
          -7px -7px 9px rgba(255, 255, 255, 0.65); */

          border-radius: 59px;
          background: #ecf0f3;
          box-shadow: 11px 11px 28px 0px rgba(212, 216, 220, 0.9),
              -11px -11px 22px 0px rgba(255, 255, 255, 0.9),
              11px -11px 22px 0px rgba(212, 216, 220, 0.2),
              -11px 11px 22px 0px rgba(212, 216, 220, 0.2),
              -1px -1px 2px 0px rgba(212, 216, 220, 0.5) inset;
      }

      .itemwidth {
          width: 700px;
      }

      .moreBtn {
          width: 35px;
          height: 35px;
          border-radius: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 30px;

          /* box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.15),
          -3px -3px 5px rgba(255, 255, 255, 0.65); */
      }

      input.form-control {
          margin: 0 5% 0 5%;
          background: #ecf0f3;
          border: 0;
          /* border-bottom: 1px solid; */
          border-radius: 0;
      }

      input.form-control:focus {
          background: #ecf0f3;
      }

      button.moreBtn {
          border: none;
          margin-right: 5%;
          width: 45px !important;
      }

      .section-bg {
          /* box-shadow: 0px 20px 30px rgba(100, 131, 177, 0.2); */
          border-radius: 2rem;
          box-shadow: 7px 7px 9px rgba(0, 0, 0, 0.15),
              -7px -7px 9px rgba(255, 255, 255, 0.65);
      }

      .p-bg {
          align-items: center;
          text-align: center;
          /* border-radius: 35px;
        box-shadow: 7px 7px 9px rgba(0, 0, 0, 0.15),
          -7px -7px 9px rgba(255, 255, 255, 0.65); */
          border-radius: 23px;
          background: #ecf0f3;
          box-shadow: 5px 5px 13px 0px rgba(212, 216, 219, 0.9),
              -5px -5px 10px 0px rgba(255, 255, 255, 0.9),
              5px -5px 10px 0px rgba(212, 216, 219, 0.2),
              -5px 5px 10px 0px rgba(212, 216, 219, 0.2),
              -1px -1px 2px 0px rgba(212, 216, 219, 0.5) inset,
              1px 1px 2px 0px rgba(255, 255, 255, 0.3) inset;
      }

      .rp-row {
          font-size: medium;
          border-bottom: 1px solid #757f8a15;
          margin-bottom: 2%;
      }

      .status-dot {
          position: relative;
          display: inline-block;
          height: 15px;
          width: 15px;
          background: #6bc88b;
          border-radius: 50%;
          box-shadow: 5px 5px 13px 0px rgba(96, 180, 125, 0.9) inset,
              -5px -5px 10px 0px rgba(118, 220, 153, 0.9) inset,
              5px -5px 10px 0px rgba(96, 180, 125, 0.2) inset,
              -5px 5px 10px 0px rgba(96, 180, 125, 0.2) inset;
          filter: drop-shadow(-1px -1px 2px rgba(96, 180, 125, 0.5)) drop-shadow(1px 1px 2px rgba(118, 220, 153, 0.3));
      }

      /* .status-dot:after {
        content: "";
        position: absolute;
        top: 2.2px;
        left: 2.2px;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background: #6bc88b;
        animation: pulse 2s infinite;
        transform-origin: center center;
        box-shadow: 5px 5px 13px 0px rgba(96, 180, 125, 0.9) inset,
          -5px -5px 10px 0px rgba(118, 220, 153, 0.9) inset,
          5px -5px 10px 0px rgba(96, 180, 125, 0.2) inset,
          -5px 5px 10px 0px rgba(96, 180, 125, 0.2) inset;
        filter: drop-shadow(-1px -1px 2px rgba(96, 180, 125, 0.5))
          drop-shadow(1px 1px 2px rgba(118, 220, 153, 0.3));
      } */
      .status-page-link {
          padding: 10px 40px;
          font-size: 16px;
          border-bottom: 1px solid #efeff4;
          border-top: 1px solid #efeff4;
      }

      .status-page-link {
          border: 0;
          box-shadow: none;
          outline: 0;
          display: block;
          height: 100%;
      }


      .greenMark {
          width: 15px;
          height: 15px;
          flex-shrink: 0;
          border-radius: 600px;
          flex-shrink: 0;
          fill: #13ED88;
          box-shadow: 5px 5px 13px 0px rgba(17, 213, 122, 0.90) inset, -5px -5px 10px 0px rgba(21, 255, 150, 0.90) inset, 5px -5px 10px 0px rgba(17, 213, 122, 0.20) inset, -5px 5px 10px 0px rgba(17, 213, 122, 0.20) inset;
          filter: drop-shadow(-1px -1px 2px rgba(17, 213, 122, 0.50)) drop-shadow(1px 1px 2px rgba(21, 255, 150, 0.30));
      }

      .yellowMark {
          width: 15px;
          height: 15px;
          flex-shrink: 0;
          border-radius: 600px;
          flex-shrink: 0;
          fill: #E7BE36;
          box-shadow: 5px 5px 13px 0px rgba(208, 171, 49, 0.90) inset, -5px -5px 10px 0px rgba(254, 209, 59, 0.90) inset, 5px -5px 10px 0px rgba(208, 171, 49, 0.20) inset, -5px 5px 10px 0px rgba(208, 171, 49, 0.20) inset;
          filter: drop-shadow(-1px -1px 2px rgba(208, 171, 49, 0.50)) drop-shadow(1px 1px 2px rgba(254, 209, 59, 0.30));
      }

      .redMark {
          width: 15px;
          height: 15px;
          flex-shrink: 0;
          border-radius: 600px;
          flex-shrink: 0;
          fill: #FF595E;
          box-shadow: 5px 5px 13px 0px rgba(230, 80, 85, 0.90) inset, -5px -5px 10px 0px rgba(255, 98, 103, 0.90) inset, 5px -5px 10px 0px rgba(230, 80, 85, 0.20) inset, -5px 5px 10px 0px rgba(230, 80, 85, 0.20) inset;
          filter: drop-shadow(-1px -1px 2px rgba(230, 80, 85, 0.50)) drop-shadow(1px 1px 2px rgba(255, 98, 103, 0.30));
      }