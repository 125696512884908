:root {
    // --primary-light: #EB583A;
    // --primary: #EB583A;
    // --primary-dark: #EB583A;
    --primary-light: #EE8069;
    --primary: #EB583A;
    --primary-dark: #DF3412;

    --white: #FFFFFF;
    --greyLight-1: #ebf5fc;
    // --greyLight-2: #d5e0f3;
    --greyLight-2: #d5e0f3;
    --greyLight-3: #c1d9f0;
    --greyDark: #646b8c;
  }

*, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}
  
html {
    box-sizing: border-box;
    font-size: 62.5%; // 1rem = 10px    100% = 16px
    overflow-y: scroll;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    letter-spacing: .5px;
    line-height: 1.4;
}



.card {
    width: 34rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 2rem;
    background: var(--greyLight-1);
    box-shadow: 0px 20px 30px rgba(100, 131, 177, 0.2);
    padding: 5rem;
    border: 1px solid white;

    &__percent {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 3rem;
    }

    &__number {
        position: absolute;
        // color: var(--greyDark);
        color: #0c0c0c;
        font-size: 6.6rem;
        // font-family: 'DM Sans', sans-serif;
    }

    &__description {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        h2 {
            font-size: 1.8rem;
            font-family: 'DM Sans', sans-serif;
            letter-spacing: 1.2px;
            margin-bottom: 1rem;
            color: var(--primary);
        }
        p {font-size: 1.4rem;
            color: var(--greyDark);}
    }
}

svg.chart {
    position: relative;
    width: 24rem;
    height: 24rem;
    z-index: 1000;
    // transform: rotate(-90deg);
    transform: rotate(90deg);
}

svg.chart circle {
    width: 100%;
    height: 100%;
    fill: none;
    stroke-linecap: round;
    transform: translate(3rem, 3rem);
    stroke-dasharray: 565.49; // 2 * PI * radius
    stroke-dashoffset: 282.75;
    transition: all 1s ease;
    stroke-width: 24;
}
.circle {
    position: absolute;
    width: 20rem;
    height: 20rem;
    border-radius: 50%;
    box-shadow: 6px 6px 8px var(--greyLight-2),
                -6px -6px 8px var(--white);
    // background: var(--greyLight-1);

    &__medium {
        width: 20.5rem;
        height: 20.5rem;
        box-shadow: inset 8px 8px 10px var(--greyLight-2),
                    inset -4px -4px 8px var(--white);
    }
    &__small {
        width: 15.5rem;
        height: 15.5rem;
        box-shadow: 6px 6px 8px var(--greyLight-2),
                    -2px -2px 8px var(--white);
    }
}

.form {
    display: grid;
    grid-template-columns: 40% 60%;
    justify-items: center;
    padding: 4rem;
    margin-left: 4rem;
    border-radius: 2rem;
    background: var(--greyLight-1);
    text-align: center;
    box-shadow: 0px 20px 30px rgba(100, 131, 177, 0.2);
    border: 1px solid var(--white);
    position: relative;

    &__text {
        grid-column: 1 / 3;
        font-weight: 400;
        margin-bottom: 2rem;
        color: var(--greyDark);
    }

    input {
        border: none;
        outline: none;
        background: none;
        font-family: inherit;
        width: 5rem;
        font-size: 1.6rem;
        text-align: center;
        padding: .8rem;
        border-radius: 1rem;
        margin-right: 1rem;
        color: var(--primary);

        box-shadow: inset .2rem .2rem .4rem var(--greyLight-2),
                    inset -.3rem -.3rem .4rem var(--white);
        &::placeholder { color: var(--greyLight-3); }
        &:focus { color: var(--primary);
            box-shadow: .3rem .3rem .6rem var(--greyLight-3), 
                            -.2rem -.2rem .5rem var(--white); }
    }

    &__btn {
        border: none;
        outline: none;
        font-family: 'DM Sans', sans-serif;
        letter-spacing: .8px;
        background: var(--greyLight-1);
        padding: .8rem 2rem;
        border-radius: 2rem;
        color: var(--white);
        cursor: pointer;
        background: linear-gradient(-25deg, var(--primary) 0%, var(--primary-light) 100%);
        
        &:hover {
            background: linear-gradient(-25deg, var(--primary-dark) 0%, var(--primary) 100%);
        }
    }

}

.tooltip {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: -6rem;
    padding: 1rem 2rem;
    border-radius: 1rem;
    background: var(--greyLight-1);
    font-size: 1.2rem;
    color: var(--primary-dark);
    opacity: 0;
    transition: all .3s ease;

    &__text { margin-left: .6rem;}

    &.fade-in { animation: fadeIn .8s ease;}
    
    &::after {
        content: '';
        position: absolute;
        left: 45%;
        bottom: 74%;
        width: 2rem;
        height: 2rem;
        background: var(--greyLight-1);
        clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
        transform: rotate(135deg);
        border-radius: 0 0 0 0.4em;
        
    }
}

.dribbble {
  position: fixed;
  font-size: 3.6rem;
  right: 2rem;
  top: 2rem;
  color: #EA4C89;
}

@keyframes fadeIn {
    0% {
      transform: translateY(-40px) scale(.5);
      opacity: 0;
    }
    100% {
      transform: translateY(0) scale(1);
      opacity: 1;
    }
  }