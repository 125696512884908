.neumorphism {
    width: 700px;
    height: 70px;
    background: #eee;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 35px;

    box-shadow: 7px 7px 9px rgba(0, 0, 0, 0.15),
        -7px -7px 9px rgba(255, 255, 255, 0.65);
}

.neumorphism_title {
    /* width: 700px; */
    /* height: 70px; */
    display: flex;
    justify-content: space-around;
    align-items: center;

    /* box-shadow: 7px 7px 9px rgba(0, 0, 0, 0.15),
        -7px -7px 9px rgba(255, 255, 255, 0.65); */



    /* width: 700px;
        height: 50px; */
    /* background: #ecf0f3; */
    /* display: flex;
        justify-content: space-around;
        align-items: center; */
    /* border-radius: 35px; */
    /* box-shadow: 7px 7px 9px rgba(0, 0, 0, 0.15), -7px -7px 9px rgba(255, 255, 255, 0.65); */
    border-radius: 39px;
    background: #ecf0f3;
    box-shadow: 11px 11px 28px 0px rgba(212, 216, 220, 0.9), -11px -11px 22px 0px rgba(255, 255, 255, 0.9), 11px -11px 22px 0px rgba(212, 216, 220, 0.2), -11px 11px 22px 0px rgba(212, 216, 220, 0.2), -1px -1px 2px 0px rgba(212, 216, 220, 0.5) inset;
    padding-top: 1%;
    padding-bottom: 1%;
}






.itemwidth {
    width: 700px;
    padding-left: 2%;
    padding-right: 2%;
}

.moreBtn {
    width: 35px;
    height: 35px;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;

    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.15),
        -3px -3px 5px rgba(255, 255, 255, 0.65);
}

body {
    height: calc(100vh - 25px);
    /* background-color: #eee !important; */
    background: #eef2f5 !important;
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: #404040;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    align-content: center;
    background: #eee;
}

input.form-control {
    margin: 0 5% 0 5%;
    background: #eee;
    border: 0;
    border-bottom: 1px solid;
    border-radius: 0;
}

input.form-control:focus {
    background: #eee;
}

button.moreBtn {
    border: none;
    margin-right: 5%;
    width: 45px !important;
}

.makecenter {
    padding-bottom: 10%;
    /* padding-top: 20%; */
}

@media screen and (max-width: 1080px) {
    .makecenter {
        /* padding-bottom: 70%; */
    }
}

video {
    z-index: -999;
    object-fit: cover;
    /*width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0; */
    /* object-fit: initial; */
    width: 100%;
    height: 100vh;
    position: fixed;
    /* top: 0; */
    left: 0;
    bottom: 0;
}

.viewport-header {
    position: relative;
    height: 100vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

details>summary {
    list-style: none;
}

details summary::-webkit-details-marker {
    display: none;
}

input#name,
input#email,
.neumorphism {
    font-size: 2rem;
}

.form-control {

    font-size: 2rem !important;
}